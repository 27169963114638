import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import Pagination from "./pagination";
import "../css/menu.scss";
import Video from "./Video";
import { useLayoutEffect } from "react";

export default function MainMenuItem({category, color, onSub, onExpand, playThumbnail, onThumbnailEnd, onClose}) {

    const history = useHistory();

    const [open, setOpen] = useState(false);
    const isPlaying = useRef(false);
    //const video = useRef(null);
    // const largeImage = useRef(null);
    const [zIndex, setZIndex] = useState(0);
    const [showBtns, setShowBtns] = useState(false);
    //const [showPlayBtn, setShowPlayBtn] = useState(true);
    const [player, setPlayer] = useState(null);
    const [videoReady, setVideoReady] = useState(false);
    const [playerCanPlay, setPlayerCanPlay] = useState(false);
    const [thumbnailPlayer, setThumbnailPlayer] = useState(null);
    const [thumbnailCanPlay, setThumbnailCanPlay] = useState(false);
    
    /*const getLargeImgURL = (url) => {
        let s = url.split('.')
        return `${s[s.length-2]}_large.${s[s.length-1]}`;
    }*/

    const onThumbnailClick = () => {
        onExpand();
        history.push(`/category/${category.id}`);
    }

    const onVideo = () => {
        if(!isPlaying.current){
            //isPlaying.current = true;
            setVideoReady(true);
            //video.current.play();
            setTimeout(() => {
                setShowBtns(true);
            }, 900);
        }
    }

    const close = () => {
        setZIndex(0);
        isPlaying.current = false;
        setVideoReady(false);
        //setShowPlayBtn(true);
        onClose();
    }

    const onBtns = (open) => {
        if(!open){
            player.pause();
            setTimeout(() => {
                setPlayerCanPlay(false);
                player.dispose();
                history.push('/');
            }, 175);
        }
    }

    const onPrev = () => {
        player.pause();
        player.dispose();
        setPlayerCanPlay(false);
        onSub(`/category/${category.id}/${category.subcategories[category.subcategories.length-1].id}`);
    }

    const onNext = () => {
        player.pause();
        player.dispose();
        setPlayerCanPlay(false);
        onSub(`/category/${category.id}/${category.subcategories[0].id}`);
    }

    const playerReady = (player) => {
		
		setPlayer(player);
		
		player.on('canplay', () => {;
            setPlayerCanPlay(true);
			player.play();
		});

        player.on('ended', () => {
            player.pause();
        });

		player.on('dispose', () => {
			//console.log('player dispose');
            setPlayer(null);
		});
    }
	
    const thumbnailPlayerReady = (player) => {
        //console.log('thumbnail');
        setThumbnailPlayer(player);
        
        player.on('canplay', () => {
            setThumbnailCanPlay(true);
        });

        player.on('ended', () => {
            player.pause();
            setThumbnailCanPlay(false);
            setTimeout(() => {
                onThumbnailEnd();
            }, 650);
        });

        player.on('dispose', () => {
            //console.log('thumbnail player dispose');
            setThumbnailPlayer(null);
        });
	}

    useEffect(() => {
        if(history.location.pathname === `/category/${category.id}`){
            setZIndex(1);
            setOpen(true);
            //setShowPlayBtn(false);
        }else{
            setShowBtns(false);
        }

        return () => {
            setOpen(false);
        }
    }, [history.location.pathname, category.id]);

    useLayoutEffect(() => {
        if(playThumbnail === false){
            setThumbnailCanPlay(false);
            thumbnailPlayer?.dispose();
        }
    }, [playThumbnail, thumbnailPlayer]);

    return (
        <div className="menu-item" style={{color: color ? color : '#000'}}>
            <AnimateSharedLayout>
                {open ?
                    <motion.div className='expanded-item' layoutId="expandable-item" style={{backgroundImage: `url(${category.image_url})`, backgroundSize: 'cover'}} transition={{ease: 'easeOut'}} onLayoutAnimationComplete={onVideo}>
                        <div className="v-background" style={playerCanPlay ? {opacity: 1} : {opacity: 0}}>
                            {videoReady &&
                                <Video autoPlay={true} onReady={playerReady} options={{
                                    sources: [{
                                        src: `https://stream.mux.com/${category.video_url}.m3u8`,
                                        type: 'application/x-mpegURL'
                                    }]
                                }} />
                            }
                            {/* <video ref={video} className={videoReady ? 'w-100' : 'd-none'} muted> */}
                                {/* <source src={category.video_url} type="video/mp4" /> */} {/*FOR PRODUCTION USE THIS LINE*/}
                                {/* <source src={`https://s3.us-east-1.amazonaws.com/s3.museum.amesconstruction.com/g-expansive-fleet/1080/category/${category.video_url.split('/')[category.video_url.split('/').length-1]}`} type="video/mp4" /> */}
                            {/* </video> */}
                        </div>
                        <div className="content">
                            <h1 className={color ? 'text-shadow' : ''}>{category.name}</h1>
                            <AnimatePresence>
                                {showBtns &&
                                    <div className="btn-container">
                                        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}
                                            className={category.subcategories.length === 10 || category.subcategories.length === 5 || category.subcategories.length === 7 ? 'btns' : 'btns cols-2'} key={'btns'}
                                            onAnimationComplete={(event) => onBtns(event.opacity === 0 ? false : true)}>
                                            
                                            {category.subcategories.map((val, i) => (
                                                <button key={`btn-${i}`} onClick={() => onSub(`/category/${category.id}/${val.id}`)}>{val.name}</button>
                                            ))}
                                            {category.subcategories.length === 5 &&
                                                <button className="invisible" aria-hidden></button>
                                            }
                                        </motion.div>
                                    </div>
                                }
                            </AnimatePresence>
                        </div>
                       <Pagination catClick={() => setShowBtns(false)} prevClick={onPrev} nextClick={onNext} />
                    </motion.div>
                :
                    <motion.div onClick={onThumbnailClick}
                        className="normal-item" layoutId="expandable-item"
                        style={{backgroundImage: `url(${category.image_url})`, zIndex: zIndex}}
                        // The initial and animate are to stop the image from jumping on expand/contract
                        initial={{backgroundSize: '100% 100%', border: '0rem solid #F15A31'}} animate={{backgroundSize: 'cover', border: '0.57rem solid #F15A31'}}
                        transition={{ease: 'easeOut'}} onLayoutAnimationComplete={close}>
                        <>
                            {playThumbnail &&
                                <>
                                 {/*<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>*/}
                                    <>
                                    <div className="video-thumbnail" style={thumbnailCanPlay ? {opacity: 1} : {opacity: 0}}>
                                        <Video autoPlay={true} onReady={thumbnailPlayerReady} options={{
                                            autoPlay: true,
                                            sources: [{
                                                src: `https://stream.mux.com/${category.video_url}.m3u8`,
                                                type: 'application/x-mpegURL'
                                            }]
                                        }} />
                                    </div>

                                    {/* <video className="thumbnail" autoPlay muted onEnded={onThumbnailEnd}> */}
                                        {/*<source src={category.video_url} type="video/mp4" />*/} {/*FOR PRODUCTION USE THIS LINE*/}
                                        {/* <source src={`https://s3.us-east-1.amazonaws.com/s3.museum.amesconstruction.com/g-expansive-fleet/ORIGINAL/${category.video_url.split('/')[category.video_url.split('/').length-1]}`} type="video/mp4" /> */}
                                    {/* </video> */}
                                    </>
                                 {/*</motion.div>*/}
                                </>
                            }
                        </>
                        <h1 className={color ? 'text-shadow' : ''}>{category.name}</h1>
                        {/* {showPlayBtn &&
                            <motion.button initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                                <img src="/images/play-btn.png" alt="play" />
                            </motion.button>
                        } */}
                    </motion.div>
                }
            </AnimateSharedLayout>
        </div>
    );
}
