import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import MainMenuItem from "./mainMenuItem";
import siteData from "../content/site_data.json";
import { useEffect } from "react";

export default function MainMenu() {

    const history = useHistory();
    const [show, setShow] = useState(true);
    const [hideGrid, setHideGrid] = useState(false);
    const [playThumbnail, setPlayThumbnail] = useState([false, false, false, false, false, false]);
    const page = useRef('/');
    const playingIndex = useRef(0);

    const container = {
		hidden: { opacity: 0 },
		show: {
            opacity: 1,
            transition: {
                opacity: {
                    delay: 0.25
                }
            }
        }
  	}

    const handleMenuItemClick = (url) => {

        //console.log("handleMenuItemClick");
        page.current = url;
        setShow(false);
        
    };

    const onAnimComplete = (event) => {
        if(event === 'hidden'){
            history.push(page.current);
        }
    }

    const stopAll = () => {
       // console.log('stop all');
        setPlayThumbnail([false, false, false, false, false, false]);
        setHideGrid(true)
    }

    const play = (i) => {
        //console.log('play');
        let arr = [false, false, false, false, false, false];
        arr[i] = true;
        setPlayThumbnail(arr);
        playingIndex.current = i;
    }

    const onClose = () => {
        play(playingIndex.current);
        setHideGrid(false)
    }

    useEffect(() => {
        let shouldPlay = new URLSearchParams(history.location.search).get('play');
        if(shouldPlay){
            play(playingIndex.current);
        }
    }, [history.location.search]);

    return (
        <>
        <AnimatePresence>
            {show &&
                <>
                <motion.div className="menu-grid" initial={'hidden'} animate={'show'} exit={'hidden'} variants={container} key="main-menu" onAnimationComplete={(event) => onAnimComplete(event)}
                    style={{ height: !hideGrid ? '100%' : '100vh', overflow: !hideGrid ? 'auto' : 'hidden' }}>
                    
                    <MainMenuItem color="#fff"
                        category={siteData.categories[0]}
                        onSub={(url) => handleMenuItemClick(url)}
                        onExpand={stopAll}
                        playThumbnail={playThumbnail[0]}
                        onThumbnailEnd={() => play(1)}
                        onClose={onClose}
                    />

                    <MainMenuItem color="#fff"
                        category={siteData.categories[1]}
                        onSub={(url) => handleMenuItemClick(url)}
                        onExpand={stopAll}
                        playThumbnail={playThumbnail[1]}
                        onThumbnailEnd={() => play(2)}
                        onClose={onClose}
                    />

                    <MainMenuItem
                        category={siteData.categories[2]}
                        onSub={(url) => handleMenuItemClick(url)}
                        onExpand={stopAll}
                        playThumbnail={playThumbnail[2]}
                        onThumbnailEnd={() => play(3)}
                        onClose={onClose}
                    />

                    <MainMenuItem
                        category={siteData.categories[3]}
                        onSub={(url) => handleMenuItemClick(url)}
                        onExpand={stopAll}
                        playThumbnail={playThumbnail[3]}
                        onThumbnailEnd={() => play(4)}
                        onClose={onClose}
                    />

                    <MainMenuItem
                        category={siteData.categories[4]}
                        onSub={(url) => handleMenuItemClick(url)}
                        onExpand={stopAll}
                        playThumbnail={playThumbnail[4]}
                        onThumbnailEnd={() => play(5)}
                        onClose={onClose}
                    />
                
                    <MainMenuItem color="#fff"
                        category={siteData.categories[5]}
                        onSub={(url) => handleMenuItemClick(url)}
                        onExpand={stopAll}
                        playThumbnail={playThumbnail[5]}
                        onThumbnailEnd={stopAll} // Since this uses Mux, we dont want it to keep playing, if left open for a whole day then there will be 24hrs of costs.
                        onClose={onClose}
                    />
                </motion.div>
                
                <motion.footer initial={'hidden'} animate={history.location.pathname === '/' ? 'show' : 'null'} exit={{display: 'none'}} variants={container} key="main-footer">
                    Touch image to learn more about equipment category
                </motion.footer>
                </>
            }
        </AnimatePresence>
        </>
    );
}
