import { useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import useFitText from "use-fit-text";
import siteData from "../content/site_data.json";
import Pagination from "./pagination";
import '../css/subcategory.scss';
import Video from "./Video";

export default function Subcategory() {

	const history = useHistory();
	
	let { category_id, subcategory_id } = useParams();
	const category = _.find(siteData.categories, { id: category_id });
	const subcategory = _.find(category.subcategories, {id: subcategory_id});
   const content = subcategory.content;
	const [show, setShow] = useState(true);
	const [showContent, setShowContent] = useState(true);
	const page = useRef('/');
	const [player, setPlayer] = useState(null);

	const container = {
		hidden: {  },
		show: {
			transition: {
				staggerChildren: 0.3
			}
		}
  	}

	const item = {
		hidden: { opacity: 0 },
		show: { opacity: 1}
	}

	const onAnimComplete = (open) => {
		if(!open){
			history.push(page.current);
			setShowContent(true);
		}
	}

	const onExit = (open) => {
		if(!open){
			history.push(page.current);
		}
	}

	const onPrev = () => {
		pauseVideo();
		let i = category.subcategories.findIndex((elem) => elem.id === subcategory_id);
		if(i > 0){
			page.current = `/category/${category_id}/${category.subcategories[i-1].id}`;
			setShowContent(false);
		}else{
			page.current = `/category/${category_id}`;
			setShow(false);
		}
	}

	const onNext = () => {
		pauseVideo();
		let i = category.subcategories.findIndex((elem) => elem.id === subcategory_id);
		if(i < category.subcategories.length-1){
			page.current = `/category/${category_id}/${category.subcategories[i+1].id}`;
			setShowContent(false);
		}else{
			page.current = `/category/${category_id}`;
			setShow(false);
		}
	}

	const onHome = () => {
		pauseVideo();
		page.current = '/?play=true';
		setShow(false);
	}

	const pauseVideo = () => {
		let video = document.querySelector('video');
		if(!video.paused){
			player.pause();
		}
		video.style.opacity = 0;
		setTimeout(() => {
			dispose();
		}, 650);
	}

	const playerReady = (player) => {
		
		setPlayer(player);
		let video = document.querySelector('video');
		video.style.opacity = 1;
		
		/*player.on('canplay', () => {
			player.play();
		});*/

		/*player.on('dispose', () => {
			console.log('player dispose');
		});*/
	}

	const dispose = () => {
		player.dispose();
	}

	return(
		<AnimatePresence>
			{show &&
				<motion.main className="subcategory" initial={{ opacity: 1 }} exit={{ opacity: 0 }} onAnimationComplete={(event) => onExit(event.opacity === 0 ? false : true)}
					key={`${subcategory_id}-main`}>
					<AnimatePresence>
					{showContent &&
						<motion.div className="s-row" variants={container} initial={'hidden'} animate={'show'} exit={'hidden'}
							key={`${subcategory_id}-row`} onAnimationComplete={(event) => onAnimComplete(event === 'hidden' ? false : true)}>
							
							<div className="s-col">
								{content.heading.length > 15 ?
									<ScaleH1>{content.heading}</ScaleH1>
								: <motion.h1 variants={item}>{content.heading}</motion.h1>}
								{content.main.map((elem, i) => (
									<motion.div variants={item} key={`${subcategory_id}-${i}`}>
										{elem.content.heading && 
											<h2>{elem.content.heading}</h2>
										}
										<p dangerouslySetInnerHTML={{ __html: elem.content.text }}></p>
									</motion.div>
								))}
							</div>
							<div className="s-col">
								<Video onReady={playerReady} autoPlay options={{
									sources: [
										{
											src: `https://stream.mux.com/${subcategory.video_url}.m3u8`,
											type: 'application/x-mpegURL'
										}
									]
								}} />
							</div>
							<Pagination catClick={onHome} prevClick={onPrev} nextClick={onNext} />
						</motion.div>
					}
					</AnimatePresence>
				</motion.main>
			}
		</AnimatePresence>
	)
}

const ScaleH1 = ({children}) => {

	const [isSized, setIsSized] = useState(false);
	const onSize = () => { setIsSized(true); }
	const { fontSize, ref } =  useFitText({minFontSize: 100, maxFontSize: 2000, onFinish: onSize});

	return(
		<div>
			<h1 ref={ref} style={{ fontSize, height: '100%', width: '100%', opacity: isSized ? 1 : 0, transition: 'opacity 500ms' }}>
				{children}
			</h1>
		</div>
	)
}