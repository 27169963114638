import { useEffect, useState } from "react";
import _ from "lodash";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/main.scss";

import MainMenu from "./components/mainMenu";
import Subcategory from "./components/subcategory";
// import VideoTest from "./components/videotest";

global._ = _;

export default function App() {

    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        setFirstLoad(false);
    }, []);

    return (

        <Router>

            <Switch>

                {/* <Route path={"/videotest"}>
                    <VideoTest />
                </Route> */}

                <Route path={"/category/:category_id/:subcategory_id"}>
                    <Subcategory />
                </Route>

                <Route path={"/category/:category_id"}>

                    <MainMenu />

                </Route>

                <Route path={"/"}>
                    {firstLoad ?
                        <Redirect to='/?play=true' />
                    :
                        <MainMenu />
                    }

                </Route>

            </Switch>

        </Router>

    );
}
